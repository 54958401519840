const OfficeHours = ()=>(
  <div style={{paddingTop: "20%", paddingBottom: "20%"}}>
    <h2>Office Hours</h2>
    <p>
      Tuesday 10am - 7pm<br/>
      Wednesday 10am - 7pm<br/>
      Thursday 9am - 12pm<br/>
    </p>
  </div>
)

export default OfficeHours
