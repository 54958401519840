import { Container } from "react-bootstrap"
import { Navbar } from "react-bootstrap"
import { Nav } from "react-bootstrap"
import useScrollSpy from 'react-use-scrollspy'
import React from 'react'

const SiteNav = (props)=> { 

  const activeSection = useScrollSpy({
    sectionElementRefs: props.sectionRefs,
    offsetPx: -80,
  });

  return (
    <Navbar id="site-navbar" className="w-100 navbar-dark" bg="dark" expand="lg" fixed="top">
      <Container className="w-100">
        <Navbar.Brand href="#home">The Center For Therapeutic Massage</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end w-100" >
            <Nav.Link href="#welcome" active={activeSection === 0}>Welcome</Nav.Link>
            <Nav.Link href="#services" active={activeSection === 1}>Services</Nav.Link> 
            <Nav.Link href="#office-hours" active={activeSection === 2}>Office-Hours</Nav.Link>
            <Nav.Link href="#contact" active={activeSection === 3}>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default SiteNav