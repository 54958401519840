import { Card, CardGroup, Container } from "react-bootstrap"

const Contact = ()=> (
  <Container>
    <h2>Contact</h2>
    <CardGroup>
       <Card>
        <iframe style={{"width": "100%", height: "100%"}} id="gmap" title="TCFTM" src="https://www.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=the+center+for+therapeutic+massage&amp;aq=&amp;sll=36.335041,-86.585999&amp;sspn=0.613978,1.312866&amp;ie=UTF8&amp;hq=the+center+for+therapeutic+massage&amp;hnear=&amp;t=m&amp;cid=1168018678069364384&amp;ll=36.389229,-86.54068&amp;spn=0.386927,0.583649&amp;z=10&amp;iwloc=A&amp;output=embed"></iframe>
      </Card>
      <Card /*style={infoStyle}*/>
        <h3>The Center For Therapeutic Massage</h3>
        <Card.Text>
          <br/>
          <p>506 Page Dr.<br/>
          Mount Juliet, TN 37122</p>
          <p>Phone: (615) 483-7721</p>
          <p>Conveniently located off of North Mount Juliet Road, less than 3 miles from I40 and the Providence Shopping Center</p>
        </Card.Text>
      </Card>

    </CardGroup> 
  </Container>
)

export default Contact