import { Container } from 'react-bootstrap'
import imgFirst from './assets/firstService.jpg'
import imgSecond from './assets/secondService.jpg'
import imgThird from './assets/thirdService.jpg'
import imgFourth from './assets/second.png'

const first = { 
  title: 'Sweedish Massage', 
  img: imgFirst,
  description: 'A systematic soft tissue manipulation applied directly to the skin via effleurage, petrissage, friction, tapotement, and vibration. A lighter pressure, relaxing massage.'
}

const second = { 
  title: 'Deep Tissue', 
  img: imgSecond,
  description: 'A style of massage that uses strong pressure; slow, deep strokes; and friction across the muscle grain to release chronic muscle tension.'
}

const third = { 
  title: 'Sports Massage', 
  img: imgThird,
  description: 'A style of massage that works specifically on problems resulting from athletic performance, training, and injury. This form of massage uses techniques similar to those of Swedish and deep-tissue massages. See also massage, deep-tissue and massage, Swedish.'
}

const fourth = { 
  title: 'Structural Integration', 
  img: imgFourth,
  description: 'A scientifically validated body therapy. Unlike massage, Structural Integration focuses not on the muscles but on their protective layer, called fascia (also known as connective tissue). Muscles are contracting tissues that give the body and organs physical movement. The fascia surrounds the muscles, bones and organs in the body. The fascia gives muscles their shape and the body its structure. Structural Integration aligns and balances the body by lengthening and repositioning the fascia. As fascia is lengthened it allows the muscles to move more efficiently. The practitioner will apply pressure to the body, working the entire fascial system in a systematic way. When restricted fascia is released and lengthened the body can return to its structurally optimal position The continuing pull of gravity, the stress of daily activities and physical injuries can pull the body out of alignment. The fascia gradually shortens, tightens and adjusts to accommodate the misalignment. When the body is out of alignment it creates inefficiency and imbalance resulting in stiffness, discomfort and loss of energy.'
}

const Services = () => (
  <Container id="services">
    <h2>Services</h2>
    <p>
      Neuromuscular Therapy (NMT) is typically used to relieve myofascial pain or dysfunction. Additionally NMT can also be utilized for preventative purposes, post-surgical care, repetitive traumas and in many cases the acute onset of idiopathic pains. Our 'Neuromuscular Therapy' is an integrated therapeutic method of clinical bodywork that combines advanced neuromuscular and deep tissue massage, MyoFascial Structural Integration, Posture Fitness training, movement education, and ergonomic modifications for daily living activities.
    </p>
    <ServiceBlock title={first.title} img={first.img} description={first.description} />
    <ServiceBlock title={second.title} img={second.img} description={second.description} />
    <ServiceBlock title={third.title} img={third.img} description={third.description} />
    <ServiceBlock title={fourth.title} img={fourth.img} description={fourth.description} />
  </Container>
)

const ServiceBlock = ( props ) => (
  <div>
    <img className="rounded-circle" width="100" height="100" src={props.img} alt='service'></img>
    <h3>{props.title}</h3>
    <p>{props.description}</p>
  </div>
)

export default Services
