import { Card, CardGroup, Container } from 'react-bootstrap'

const Pricing = () => (
  <Container className="w-75" style={{marginTop: "75px", marginBottom: "75px"}}>
    <h2>Massage Prices</h2>
    <Card style={{marginTop: "50px"}}>
      <Card.Header as="h3">Rates</Card.Header>
      <CardGroup>
        <Card>
          <Card.Body>
            <Card.Title>30 Minutes</Card.Title>
            <Card.Text>$50</Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>60 Minutes</Card.Title>
            <Card.Text>$80</Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>90 Minutes</Card.Title>
            <Card.Text>$115</Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>
    </Card>
    <Card style={{marginTop: "50px"}}>
    <Card.Header as="h3">Packages</Card.Header>
      <CardGroup>
        <Card>
          <Card.Body>
            <Card.Title>4 - 30 Minute Sessions</Card.Title>
            <Card.Text>$180</Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>3 - 60 Minute Sessions</Card.Title>
            <Card.Text>$225</Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>3 - 90 Minute Sessions</Card.Title>
            <Card.Text>$330</Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>
    </Card>
    <br/>
    <i>I do not charge for extras or add-on services, because each massage should be unique and naturally requires different techniques. Because of this, prices are based upon the length of the session.</i>
  </Container>
)

export default Pricing
