import './App.css'
import React, { useRef } from 'react'
import WelcomeCarousel from './WelcomeCarousel'
import Welcome from './Welcome'
import Testimonial from './Testimonial'
import Services from './Services'
import SiteNav from './SiteNav'
import OfficeHours from './OfficeHours'
import Contact from './Contact'
import Footer from './Footer'
import Pricing from './Pricing'

function App() {

  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  return (
    <div className="App">
      <header className="App-header">
        <SiteNav sectionRefs={sectionRefs} />
      </header>

      <section id="welcome" ref={sectionRefs[0]} style={{paddingTop: "15vh", paddingBottom: "15vh"}}>
        <WelcomeCarousel />
        <Welcome />
      </section>
      <section className="bg-light" style={{paddingTop: "15vh", paddingBottom: "15vh"}}>
        <Testimonial /> 
      </section>
      <section id="services" ref={sectionRefs[1]} style={{paddingTop: "15vh", paddingBottom: "15vh"}}>
        <Services />
        <Pricing />
      </section>
      <section id="office-hours" className="bg-light" ref={sectionRefs[2]} style={{paddingTop: "5vh", paddingBottom: "5vh"}}>
        <OfficeHours />
      </section>
      <section id="contact" ref={sectionRefs[3]} style={{paddingTop: "15vh", paddingBottom: "15vh"}}>
        <Contact />
      </section>

      <footer className="bg-dark" style={{minHeight: "30vh"}}>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
