import photo from './assets/Debbie_Iddins-2.jpg'
import { Card, Container } from 'react-bootstrap'

const Testimonial = () => (
  <div style={{display: "inline-block", paddingTop: "75px"}}>
    <Card style={{ display: "inline-block", maxWidth: "800px", padding: "30px"}}>
      <Card.Img variant="top" src={photo} className="rounded-circle" style={{width: "100px", alignSelf: "center"}} />
      <Card.Body>
        <Card.Text>
          "I believe that each of us has a purpose for our lives. I think we are given certain gifts and attributes that allow us to fulfill that purpose, so that we can live our best life, and in doing so, enrich other’s lives as well. Massage therapy has given me that fulfillment. It is a wonderful thing to serve my clients in a way that brings healing, and a heightened sense of his or her wellbeing. I know my work plays a part in the client’s natural healing process."
        </Card.Text>
        <br/>
        <Card.Text>
          <i>{"- Debbie Iddins, Owner & Licensed Massage Therapist"}</i>
        </Card.Text>
      </Card.Body>
    </Card>
    <Container style={{paddingTop: "75px"}}>
      <p>Debbie Iddins, LMT, CNMT graduated from the Core Institute of Tennessee School of Massage Therapy and Structural Bodywork. She is a Certified Neuromuscular Therapist, Certified CORE Myofascial Therapist, and CORE Certified Strength and Conditioning Assistant. Debbie owns and operates The Center For Therapeutic Massage, a clinical massage practice which she originally opened in Maryville, TN, in 2001. In Febuary of 2009, she relocated to Mt. Juliet, TN, and shortly thereafter, she reopened her massage practice, which specializes in Injury Rehabilitation, Structural Integration, Sports, and Deep Tissue Therapies.</p>
      <p>She also worked as a massage therapist at Blount Memorial Hospital, and Black Berry Farms Resort. Debbie has studied Active Isolated Stretching (Aaron Mattis), Neuromuscular Therapy (American Version), Myofascial Therapy Anatomy Trains (Tom Myers), Sports Massage, Geriatric Massage, and Craniosacral Therapy.</p>
    </Container>
  </div>
)

export default Testimonial
