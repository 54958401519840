import { Carousel } from 'react-bootstrap'
import first from './assets/first.jpg'
import second from './assets/second.png'
import third from './assets/third.jpg'

const WelcomeCarousel = () => (
  <Carousel className="d-inline-block w-50">
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={first}
        alt="First slide"
      />

      <Carousel.Caption>
        <h3>The Center For Therapeutic Massage</h3>
        <p>Clinical massage in a relaxing atmosphere...</p>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={second}
        alt="Second slide"
      /> 

      <Carousel.Caption>
        <h3>The Center For Therapeutic Massage</h3>
        <p>Clinical massage in a relaxing atmosphere...</p>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={third}
        alt="Third slide"
      />

      <Carousel.Caption>
        <h3>The Center For Therapeutic Massage</h3>
        <p>Clinical massage in a relaxing atmosphere...</p>
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
)

export default WelcomeCarousel